import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInPanel } from "./SignInPanel"
import { Navbar } from "./Navbar";

export function PageLayout(props) {
    return (
        <>
            <AuthenticatedTemplate>
                <div id="container">
                    <Navbar />
                    <div>
                        {props.children}
                    </div>
                </div>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <SignInPanel />
            </UnauthenticatedTemplate>
        </>
    );
};