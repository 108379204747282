import React, { useEffect } from "react";

export function Home() {
    useEffect(() => {
        document.title = "Platform Manager | Home";
    }, []);

    return (
        <>
            <h2>Logged in</h2>
        </>
    );
}