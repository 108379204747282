import React from "react";
import { Link } from "react-router-dom";
import { SignOutButton } from "./SignOutButton";

export function Navbar() {
  return (
    <aside>
      <div className="logo"></div>
      <nav>
        <div className="main">
          <Link to="/RegionZoneHost">
            <div className="icon-wrapper">
              <i className="fa-solid fa-house"></i>
            </div>
            Region Zone Host
          </Link>
          <Link to="RegionZoneHostIP">
            <div className="icon-wrapper">
              <i className="fa-sharp fa-solid fa-square-parking"></i>
            </div>
            Region Zone Host IPs
          </Link>
          <Link to="PairedHosts">
            <div className="icon-wrapper">
              <i className="fa-solid fa-parachute-box"></i>
            </div>
            Region Zone Paired Hosts
          </Link>
          <Link to="AccountUsage">
            <div className="icon-wrapper">
              <i className="fa-solid fa-spray-can"></i>
            </div>
            Account Usage
          </Link>
          <Link to="BrandUsage">
            <div className="icon-wrapper">
              <i className="fa-solid fa-bucket"></i>
            </div>
            Brand Usage
          </Link>
          <Link to="IPAddressRange">
            <div className="icon-wrapper">
              <i className="fa-solid fa-person-digging"></i>
            </div>
            IP Address Ranges
          </Link>
          <Link to="SBCUsers">
            <div className="icon-wrapper">
              <i className="fa-solid fa-server"></i>
            </div>
            SBC Users
          </Link>
        </div>
        <div className="lower">
          <SignOutButton />
        </div>
      </nav>
    </aside>
  );
};
