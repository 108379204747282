import axios from "axios";
import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

export default class ApiClient {
  static async getApiClient() {
    let accessToken;
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    accessToken = response.accessToken;

    return axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }
}