import React, { useState, useEffect } from "react";
import CallMsApi from "../api/CallMSAPI";
import TableWrapper from "../utils/tableWrapper";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

export function RegionZoneHostIP() {
  const [testData, setTestData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Platform Manager | Region Zone Host IPs";

    CallMsApi.getRegionZoneHostIPs()
      .then((res) => {
        setTestData(res.data.Result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const columns = [
    {
      Header: "id",
      accessor: "id",
    },
    {
      Header: "regionZoneHostId",
      accessor: "regionZoneHostId",
    },
    {
      Header: "regionZoneHost",
      accessor: "regionZoneHost",
    },
    {
      Header: "ip",
      accessor: "ip",
    },
    {
      Header: "privateIp",
      accessor: "privateIp",
    },
    {
      Header: "isPrimary",
      accessor: "isPrimary",
      Cell: (props) => {
        return <span>{JSON.stringify(props.value)}</span>;
      },
    },
    {
      Header: "ipAddressRangeId",
      accessor: "ipAddressRangeId",
    },
    {
      Header: "regionZoneHostType",
      accessor: "regionZoneHostType",
    },
  ];

  if (error) {
    return (
      <div className="py-5 px-2">
        <Alert key={"danger"} variant={"danger"}>
          {error}
        </Alert>
      </div>
    );
  }

  if (testData && testData.length > 0) {
    return <TableWrapper columns={columns} data={testData} />;
  }
  else {
    return (
      <div className="translate-middle py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
};