import { Routes, Route, useNavigate } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import { PageLayout } from "./ui-components/PageLayout";
import { Home } from "./pages/Home";
import { AccountUsage } from "./pages/AccountUsage"
import { BrandUsage } from "./pages/BrandUsage"
import { IPAddressRange } from "./pages/IPAddressRange"
import { PairedHosts } from "./pages/PairedHosts"
import { RegionZoneHost } from "./pages/RegionZoneHost"
import { RegionZoneHostIP } from "./pages/RegionZoneHostIP"
import { SBCUsers } from "./pages/SBCUsers"

function App({ pca }) {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <PageLayout>
        <Pages />
      </PageLayout>
    </MsalProvider>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/AccountUsage" element={<AccountUsage />} />
      <Route path="/BrandUsage" element={<BrandUsage />} />
      <Route path="/IPAddressRange" element={<IPAddressRange />} />
      <Route path="/PairedHosts" element={<PairedHosts />} />
      <Route path="/RegionZoneHost" element={<RegionZoneHost />} />
      <Route path="/RegionZoneHostIP" element={<RegionZoneHostIP />} />
      <Route path="/SBCUsers" element={<SBCUsers />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
