import React, { useState, useEffect } from "react";
import CallMsApi from "../api/CallMSAPI";
import moment from "moment";
import TableWrapper from "../utils/tableWrapper";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

export function RegionZoneHost() {
    const [testData, setTestData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = "Platform Manager | Region Zone Hosts";

        CallMsApi.getRegionZoneHost()
            .then((res) => {
                setTestData(res.data.Result);
            })
            .catch((err) => {
                setError(err.message);
            });
    }, []);

    const columns = [
        {
            Header: "Region Zone",
            accessor: "regionZone",
        },
        {
            Header: "Code",
            accessor: "code",
        },
        {
            Header: "Hostname",
            accessor: "hostname",
        },
        {
            Header: "Media Gateway SIP Port",
            accessor: "mediaGatewaySIPPort",
        },
        {
            Header: "Media Gateway Transport",
            accessor: "mediaGatewayTransport",
        },
        {
            Header: "Created",
            accessor: "createdOn",
            Cell: (props) => {
                let temp = moment.utc(props.value);
                let val = moment(temp).local();
                return <span>{val.format("DD/MM/YY")}</span>;
            },
        },
        {
            Header: "Host Type",
            accessor: "regionZoneHostType",
        },
        {
            Header: "Service Id",
            accessor: "systemAccountServiceId",
        },
        {
            Header: "User Allocation Limit",
            accessor: "userAllocationLimit",
        },
        {
            Header: "Registrations Per Minute AllocationLimit",
            accessor: "registrationsPerMinuteAllocationLimit",
        },
        {
            Header: "Teams Media Bypass",
            accessor: "teamsMediaBypass",
        },
        {
            Header: "AzureStorage Url",
            accessor: "azureStorageUrl",
        },
    ];

    if (error) {
        return (
            <div className="py-5 px-2">
                <Alert key={"danger"} variant={"danger"}>
                    {error}
                </Alert>
            </div>
        );
    }

    if (testData && testData.length > 0) {
        return <TableWrapper columns={columns} data={testData} />;
    }
    else {
        return (
            <div className="translate-middle py-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
};
