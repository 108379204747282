import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

export function SignOutButton() {
    const { instance } = useMsal();

    const handleSignOut = () => {
        instance.logoutRedirect();
    }

    return (
        <Link title="Sign Out" onClick={handleSignOut} to="/">
            <div className="icon-wrapper">
                <i className="fa-solid fa-person-through-window"></i>
            </div>
            Sign Out
        </Link>
    );
};