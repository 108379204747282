import ApiClient from "./ApiClient";

export default class CallMsApi {
  static async getIPRange() {
    var client = await ApiClient.getApiClient();
    return client.get("/IPAddressRange");
  }

  static async getRegionZoneHost() {
    var client = await ApiClient.getApiClient();
    return client.get("/RegionZoneHost");
  }

  static async getRegionZoneHostIPs() {
    var client = await ApiClient.getApiClient();
    return client.get("/RegionZoneHostIP");
  }

  static async getRegionZonePairedHosts() {
    var client = await ApiClient.getApiClient();
    return client.get("/RegionZoneHost/accountUsage");
  }

  static async getAccountUsage() {
    var client = await ApiClient.getApiClient();
    return client.get("/RegionZoneHost/accountUsage");
  }

  static async getBrandUsage() {
    var client = await ApiClient.getApiClient();
    return client.get("/RegionZoneHost/brandUsage");
  }

  static async getSBCUsers() {
    var client = await ApiClient.getApiClient();
    return client.get("/SBCUsers");
  }
}
