import React, { useState, useEffect } from "react";
import CallMsApi from "../api/CallMSAPI";
import moment from "moment";
import TableWrapper from "../utils/tableWrapper";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

export function IPAddressRange() {
  const [testData, setTestData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Platform Manager | IP Address Range";

    CallMsApi.getIPRange()
      .then((res) => {
        setTestData(res.data.Result);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  const columns = [
    {
      Header: "name",
      accessor: "name",
    },
    {
      Header: "ipAddressType",
      accessor: "ipAddressType",
    },
    {
      Header: "cidr",
      accessor: "cidr",
    },
    {
      Header: "Created",
      accessor: "createdOn",
      Cell: (props) => {
        let temp = moment.utc(props.value);
        let val = moment(temp).local();
        return <span>{val.format("DD/MM/YY")}</span>;
      },
    },
  ];
  if (error) {
    return (
      <div className="py-5 px-2">
        <Alert key={"danger"} variant={"danger"}>
          {error}
        </Alert>
      </div>
    );
  }
  if (testData && testData.length > 0) {
    return <TableWrapper columns={columns} data={testData} />;
    //return JSON.stringify(testData);
  } else {
    return (
      <div className="translate-middle py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
};