import { SignInButton } from "./SignInButton";

export function SignInPanel() {
    return (
        <>
            <div className="signInPanelWrapper">
                <div className="signInPanel">
                    <h2>Platform Manager Login</h2>
                    <div className="buttonContainer">
                        <SignInButton />
                    </div>
                </div>
            </div>
        </>
    );
}