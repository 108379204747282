import { useMsal } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { loginRequest } from "../authConfig";

export function SignInButton() {
    const { instance } = useMsal();

    const handleSignIn = () => {
        instance.loginRedirect(loginRequest);
    }

    return (
        <Button title="Sign In" onClick={handleSignIn}>Sign In</Button>
    );
}